<template>
  <v-dialog v-model="drawer" max-width="600" scrollable>
    <v-card>
      <v-card-title class="my__v-card-title">
        <div>
          <div>คำแนะนำ</div>
          <div class="text-caption" style="margin-top: -6px;"></div>
        </div>
        <v-icon @click="drawer=false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="my__v-card-text">
        <div class="px-3 pb-3" data-explain="lorem500">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab ad adipisci asperiores at, cupiditate distinctio
          eos exercitationem id impedit ipsa iste laborum libero modi molestiae neque officiis omnis optio perferendis
          quas quasi recusandae reprehenderit repudiandae suscipit! Atque blanditiis culpa cum cupiditate debitis
          deserunt dignissimos dolore ea eveniet ex expedita facilis fugit id labore laudantium magni molestiae mollitia
          nam neque nisi obcaecati odit optio perferendis placeat, quae quaerat reprehenderit repudiandae saepe sequi
          sint soluta suscipit tenetur ut. Ab accusantium animi commodi cumque deserunt distinctio, dolore esse facilis
          fugit minima, nostrum optio quaerat quasi recusandae repellat sed soluta temporibus voluptatum! Ad atque autem
          eius quam quod sed. A aliquam consectetur consequuntur corporis debitis delectus dolor doloribus error
          expedita facere, inventore iure laborum maiores minus modi mollitia nesciunt placeat quam quisquam quod
          repellat soluta tempora totam voluptas voluptates. Ab debitis dignissimos doloremque ea earum eligendi
          exercitationem harum labore magni neque nesciunt numquam placeat quae, quaerat quidem soluta tempora ullam vel
          veniam voluptatum. Cumque, ea eaque facere fugit quos repellendus sint sit vitae. Doloremque doloribus ducimus
          enim eveniet, explicabo iusto laborum quod vel? Debitis dignissimos eligendi error, esse est harum ipsa
          laudantium libero, odio quidem tempora tenetur vero voluptatem. Dicta dolore dolorum enim itaque nesciunt
          nihil nobis quos vitae. Amet animi asperiores consectetur consequuntur corporis dicta dolorum explicabo fuga
          illo libero numquam, optio pariatur, placeat quod rerum sint unde ut velit voluptatem voluptatum. Accusamus
          adipisci aliquam aliquid aspernatur, beatae, culpa cum debitis deleniti deserunt doloremque earum eligendi eos
          esse est fugiat impedit ipsum laborum magnam magni minima modi molestias neque nihil odio odit omnis optio
          pariatur placeat praesentium quas quasi quibusdam saepe sapiente sequi sit ullam voluptatem? Accusamus, ad
          alias amet animi aspernatur atque commodi consectetur consequuntur cum cupiditate doloremque dolores ea earum
          esse et, ex iste iusto nihil nobis non odit pariatur placeat qui, quidem reprehenderit soluta vel veritatis?
          Eum explicabo hic odio possimus quos repellat sed, similique sunt voluptas voluptatibus! Aliquam amet
          asperiores aut beatae consequatur cumque deserunt dignissimos earum, error et exercitationem expedita facilis
          harum hic id laudantium natus nemo nihil nulla officia porro provident quae quaerat qui quis quisquam ratione
          repellat, reprehenderit rerum ut velit vero vitae voluptatem! Accusantium adipisci assumenda blanditiis cum
          deserunt, ducimus, ipsa iste laudantium maxime necessitatibus nihil pariatur quis sequi voluptas voluptates.
          Aut earum est, eum exercitationem explicabo facere facilis ipsa iusto laborum maiores necessitatibus neque,
          quasi quos rerum saepe similique ullam. Aliquam aperiam beatae iure nemo possimus quas velit? At atque autem
          blanditiis consectetur consequatur cum delectus deserunt, dolor dolore dolorum eligendi est eum excepturi
          exercitationem expedita facere facilis fugiat inventore ipsa iusto laborum maxime natus pariatur perferendis
          quae quidem quis quisquam repellat repudiandae similique sit sunt vel vero! At cum dignissimos doloribus eum,
          expedita, impedit, ipsa iure laboriosam maiores maxime molestiae nostrum officia optio quod soluta tempore
          voluptate. Aliquam aliquid aperiam distinctio doloribus fuga fugiat, incidunt laboriosam molestiae nesciunt
          nihil nostrum obcaecati provident recusandae reiciendis suscipit tempora ullam veritatis vitae. Eveniet
          facilis fugit ipsam iusto, magni officiis pariatur quaerat sunt vel veritatis. Ab, iusto!
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SuggestionDialog',
  computed: {
    drawer: {
      get () {
        return this.$store.state.inspectionDamageDialog.openSuggestionDialog
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_SUGGESTION_DIALOG_DRAWER', to)
      }
    }
  }
}
</script>

<style scoped>

</style>
